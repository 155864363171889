import { useSelector } from "react-redux";
import Pagination from "./Pagination";
import { Link, useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

const UpcomingEvent = () => {

    const {eventData} = useSelector((state) => state.root)
    const query = useQuery()
    const page = query.get('page') || 1;

    return(
        <div className="mb-10">
            <h1 className="text-4xl mb-8 text-Secondary text-center font-extrabold uppercase ">Upcoming Event</h1>
            <div className="flex flex-wrap gap-1 justify-center items-center mb-10">
                {eventData.map((p) => (
                    <Link to={`/events/${p._id}`}>
                        <div style={{backgroundImage: `url(${p.event_image.url})`, backgroundSize:'cover'}} className={`w-[400px] h-[300px] vsmm:w-[300px] vsmm:h-[200px]  text-xl text-transparent items-end font-semibold hover:text-white `}>
                            <div className=" flex items-end w-full h-full p-5 hover:bg-opacity-80 hover:bg-Primary">{p.event_name}</div>
                           
                        </div>
                        <h1 className="text-Secondary text-center mt-5 text-2xl font-semibold">{p.event_date}</h1>
                    </Link>
                ))}   
            </div>
            <div className={eventData.length === 0 ? " hidden" : "flex justify-center mt-10 sm:mt-5 "}>
                <Pagination page={page}/>
            </div>
        </div>
    )
}

export default UpcomingEvent;