import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Services = () => {

    const { serviceData } = useSelector((state) => state.root)

    return(
        <div className="w-full flex flex-col">
            <div className="flex p-10 sm:flex-col gap-5 justify-center items-center">
                {serviceData.map((data,index) => (
                    <div key={index} className="w-full flex justify-center items-end sm:w-[350px] vsmm:h-[300px] h-[400px] vsmm:w-[300px]" style={{backgroundImage: `url(${data.services_image.url})`,backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                        <Link className="w-full sm:w-[350px] vsmm:h-[300px] h-[400px] vsmm:w-[300px]" to={`/service/${data._id}`}>
                            <div className="flex justify-center items-center flex-col inset-x-0 bottom-5 w-full  sm:w-[350px] vsmm:h-[300px] h-[400px] vsmm:w-[300px] bg-black bg-opacity-30 ">
                                <h1 className="text-4xl p5 text-center uppercase font-semibold mb-5 text-white">{data.services_title}</h1>
                                
                            </div>
                        </Link>
                    
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Services;