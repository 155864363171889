import { BiMapAlt } from "react-icons/bi"
import { AiOutlineMobile } from 'react-icons/ai'
import { HiOutlineMailOpen } from 'react-icons/hi'
import { useSelector } from "react-redux";

const ContactInfo = () => {

    const {contactData} = useSelector((state) => state.root)
    const {location,phonenumber,email} = contactData

    return (
        <div className="flex smm:flex-col smm:items-center justify-center gap-16 smm:gap-10 p-10 smm:p-0">
            <div className=" flex flex-col gap-5">
                <div className="w-[280px] flex flex-col gap-5">
                    <div className="flex gap-5 items-center">
                        <h1 className="text-Primary text-4xl"><BiMapAlt/></h1>
                        <h1 className="text-2xl"><strong>Address</strong></h1>
                    </div>
                    <h1 className="text-lg">{location}</h1>
                </div>
                <div className="w-[280px] flex flex-col gap-5">
                    <div className="flex gap-5 items-center">
                        <h1 className="text-Primary text-4xl"><AiOutlineMobile/></h1>
                        <h1 className="text-2xl"><strong>Get in Touch</strong></h1>
                    </div>
                    <h1 className="text-lg">{phonenumber}</h1>
                </div>
                <div className="w-[280px] flex flex-col gap-5">
                    <div className="flex gap-5 items-center">
                        <h1 className="text-Primary text-4xl"><HiOutlineMailOpen/></h1>
                        <h1 className="text-2xl"><strong>E-Mail</strong></h1>
                    </div>
                    <h1 className="text-lg">{email}</h1>
                </div>
            </div>
            <div>
            <iframe className="w-[600px] vs2:w-[320px] vs2:h-[200px] rounded-md h-[320px] "   src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3259.9125729538223!2d-80.7080357!3d35.2086466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x885418a7871a987b%3A0x7fc9687f8303bd5a!2s8001%20Albemarle%20Rd%2C%20Charlotte%2C%20NC%2028227%2C%20USA!5e0!3m2!1sen!2set!4v1700013198909!5m2!1sen!2set" allowfullscreen="" aria-hidden="false" tabindex="0" width="100%" height="250" frameborder="0"></iframe>
            </div>
        </div>
    )
}

export default ContactInfo;