import { useSelector } from "react-redux"

const MidFooter = () => {
    
    const { contactData } = useSelector((state) => state.root) 
    const {location,phonenumber,email} = contactData

    return(
        <div className="w-full flex-col gap-10 text-center p-10  text-white">
            <h1 className="text-2xl mb-10">Contact Us 24\7</h1>
            <div className="flex gap-5 justify-center">

                <div className="flex smm:flex-col smm:gap-5 gap-10">
                    <div className="w-[300px] flex-col gap-10 text-start">
                        <h1 className="text-xl smm:text-lg uppercase"><strong>Location:</strong></h1>
                        <h1 className="text-xl smm:text-lg">{location}</h1>
                    </div>
                    <div className="bg-white smm:hidden h-[85px] w-[2px] rounded-md"></div>
                    <div className="flex-col gap-10 text-start">
                        <h1 className="text-xl smm:text-lg uppercase"><strong>Call Us:</strong></h1>
                        <h1 className="text-xl smm:text-lg">{phonenumber}</h1>
                    </div>
                    <div className="bg-white smm:hidden h-[85px] w-[2px] rounded-md"></div>
                    <div className="flex-col gap-10 text-start">
                        <h1 className="text-xl smm:text-lg uppercase"><strong>Email:</strong></h1>
                        <h1 className="text-xl smm:text-lg ">{email}</h1>
                    </div>
                </div>
            </div>
           
        </div>
    )
}

export default MidFooter