import { useSelector } from "react-redux"
import EventIntro from "../Components/Event Components/Event Intro/EventIntro"
import UpcomingEvent from "../Components/Event Components/Upcoming Event/UpcomingEvent"
import Footer from "../Components/Footer Components/Footer"
import Navbar from "../Components/Navbar Components/Main-Navbar/Navbar"
import { useEffect } from "react"

const EventPage = () => {

    useEffect(() => {
        document.title = "Events - Debre Amin Kidus Gebriel Church"
        window.scrollTo(0, 0);
    })

    const { eventData,eventDataLength } = useSelector((state) => state.root)

    return(
        <div>
            {eventData && eventDataLength && (
                <div className="w-full">
                    <Navbar/>
                    <EventIntro/>
                    <UpcomingEvent/>
                    <Footer/>
                </div>
            )}
        </div>
    )
}

export default EventPage