import { Tabs } from "antd";
import Sidebar from "../Components/SidebarComponent/sidebar";
import TabPane from "antd/es/tabs/TabPane";
import EventIntro from "../Components/AdminEventComponents/EventIntro/EventIntro";
import EventDescription from "../Components/AdminEventComponents/EventDescription/EventDescription";
import ScreenError from "./ScreenError";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reset } from "../../Redux/auth/authSlice";

const AdminEventPage = () => {

    useEffect(() => {
        document.title = "Admin Event Page Management - Debre Amin Kidus Gebriel Church"
    })


    const {eventData,eventDataLength} = useSelector((state) => state.root)

    const [show,setShow] = useState(true)

    const ScreenSize = () => {
        if(window.innerWidth <= 1080){
            setShow(false)
        }
        else {
            setShow(true)
        }
    }

    useEffect(() => {
        ScreenSize()
        window.addEventListener('resize',() => {ScreenSize()})
    },[])

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user } = useSelector((state) => state.auth)
    
    useEffect(() => {
    
        if (!user) {
          navigate('/administrator')
        }
    
        return () => {
          dispatch(reset())
        }
      }, [user, navigate, dispatch])

    return(
        <div>
            {!show ? <ScreenError/> : <div>
                {eventData && eventDataLength && (
                    <div className="flex">
                        <Sidebar/>
                        <div className="flex flex-col w-full px-20 py-10 ml-[260px]">
                            <Tabs  defaultActiveKey="1">
                                <TabPane tab='Event Intro' key="1">
                                    <EventIntro/>
                                </TabPane>
                                <TabPane tab='Upcoming Event' key="2">
                                    <EventDescription/>
                                </TabPane> 
                            </Tabs>
                        </div>   
                    </div>
                )}
            </div>}
        </div>
        
    )
}

export default AdminEventPage;