import { useSelector } from "react-redux";
import ContactIntro from "../Components/Contact Components/ContactBanner/ContactIntro";
import ContactInfo from "../Components/Contact Components/ContactInfos/ContactInfo";
import Navbar from "../Components/Navbar Components/Main-Navbar/Navbar";
import EndFooter from "../Components/Footer Components/Bottom Footer/EndFooter";
import ContactMessage from "../Components/Contact Components/ContactMessage/ContactMessage";
import { useEffect } from "react";

const ContactPage = () => {

    useEffect(() => {
        document.title = "Contact Us - Debre Amin Kidus Gebriel Church"
        window.scrollTo(0, 0);
    })

    const { contactData } = useSelector((state) => state.root)

    return(
        <div>
            {contactData && (
                <div>
                    <Navbar/>
                    <ContactIntro/>
                    <ContactInfo/>
                    <ContactMessage/>
                    <EndFooter/>
                </div>
            )}
        </div>
    )
}

export default ContactPage;