import { useSelector } from "react-redux";

const SocialMedialLinks = () => {
    const {contactData} = useSelector((state) => state.root)
    const {instagram_link,youtube_link,twitter_link} = contactData
    return(
        <div>
            <div className="flex llg2:mt-5 mb-5 gap-20 justify-center items-center">
                <a href={youtube_link}> <i className="ri-youtube-line hover:text-Primary text-white hover:text-secondary vs2:text-xl text-2xl"></i></a>
                <a href={instagram_link}><i className="ri-instagram-line vs2:text-xl hover:text-Primary hover:text-secondary text-white text-2xl"></i></a>
                <a href={twitter_link}><i className="ri-twitter-line text-white hover:text-Primary hover:text-secondary vs2:text-xl text-2xl"></i></a>   
            </div>
        </div>
    )
}

export default SocialMedialLinks;