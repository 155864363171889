import { useSelector } from "react-redux";
import AboutIntro from "../Components/About Components/About Intro/aboutintro";
import AboutSection from "../Components/About Components/About Section/AboutSection";
import OurTeam from "../Components/About Components/OurTeamComponents/OurTeam";
import Footer from "../Components/Footer Components/Footer";
import Navbar from "../Components/Navbar Components/Main-Navbar/Navbar";
import { useEffect } from "react";

const AboutPage = () => {

    useEffect(() => {
        document.title = "About Us - Debre Amin Kidus Gebriel Church"
        window.scrollTo(0, 0);
    })

    const { aboutData, teamData } = useSelector((state) => state.root)

    return (
        <div>
            {aboutData && teamData && (
                <div className="w-full">
                    <Navbar/>
                    <AboutIntro/>
                    <AboutSection/>
                    <OurTeam/>
                    <Footer/>
                </div>
            )}
        </div>
    )
}

export default AboutPage;