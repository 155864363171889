import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Navbar from "../Components/Navbar Components/Main-Navbar/Navbar";
import Footer from "../Components/Footer Components/Footer";
import { useEffect } from "react";

const ServiceDetails = () => {
    const {id} = useParams()

    const {serviceData} = useSelector((state) => state.root)

    useEffect(() => {
        serviceData.filter(service => service._id === id).map(service => (
            document.title = `${service.services_title} - Debre Amin Kidus Gebriel Church`
        ))
        window.scrollTo(0, 0);
    },[id])

    return(
        <div>
            {serviceData && (
                <div>
                    <Navbar/>
                    {serviceData.filter(service => service._id === id).map(service => (
                        <div>
                            <div className="w-full h-[50vh] vvsm:h-[40vh]" style={{backgroundImage: `url(${service.services_image.url})`,backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
                                <div className="flex flex-col px-20 vvsm:p-3 vs2:gap-3 vvsm:gap-2 z-0 justify-center h-[50vh] bg-black bg-opacity-30 vvsm:h-[40vh] gap-5">
                                    <h1 className="text-4xl font-bold vvsm:text-2xl text-white uppercase">{service.services_title}</h1>
                                </div>
                            </div>
                            <div className="w-full flex flex-col px-32 llg:px-20 sm2:px-10  py-10 items-center justify-center">
                                <div className="flex w-full items-center">         
                                    <div dangerouslySetInnerHTML={{__html: service.services_desc}}/>
                                </div>
                            </div>
                        </div>
                    ))}
                    <Footer/>
                </div>
            )}
        </div>
    )
}

export default ServiceDetails;