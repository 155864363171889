import { useEffect } from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
    useEffect(() => {
        document.title = "Error"
        window.scrollTo(0, 0);
    },[])

    return(
        <div className="bg-black h-[100vh] flex flex-col justify-center items-center gap-5">
            <div className = "h-[50vh]">
                {/* <lottie-player src="https://assets6.lottiefiles.com/packages/lf20_q2pevjuc.json"  background="transparent"  speed="1"  loop autoplay></lottie-player> */}
                <lottie-player src="https://lottie.host/6f620595-ccb6-486f-bdfc-a1cae787dd0d/iICfAeDOOd.json" background="transparent" speed="1" loop autoplay></lottie-player>
            </div>
            <h1 className="text-xl text-white">We can`t seem to find the page you`re looking for.</h1>
            <Link to='/'><button className="bg-white rounded py-2 px-5 w-[150px]">Go to Home</button> </Link>
            
        </div>
    )
}

export default NotFoundPage;