import EndFooter from "./Bottom Footer/EndFooter"
import MidFooter from "./Mid Footer/MidFooter"
import SocialMedialLinks from "./Social Media Footer/SocialMediaLinks"
import TopFooter from "./Top Footer/TopFooter"

const Footer = () => {
    return(
        <div className="w-full bg-Secondary flex-col">
            <TopFooter/>
            <hr/>
            <MidFooter/>
            <SocialMedialLinks/>
            <hr/>
            <EndFooter/>
        </div>
    )
}

export default Footer