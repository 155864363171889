import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    bannerData: null,
    contactData: null,
    aboutData:null,
    teamData:null,
    serviceData:null,
    galleryIntroData:null,
    galleryData:null,
    galleryDataLength:null,
    videoData:null,
    videoDataLength:null,
    eventIntroData:null,
    eventData:null,
    eventDataLength:null
}

export const rootSlice =  createSlice({
    name: 'root',
    initialState,
    reducers:{
        showloading: (state,action) => {
            state.isLoading = true
        },
        hiddenloading: (state,action) => {
            state.isLoading = false
        },
        setBannerData: (state,action) => {
            state.bannerData = action.payload
        },
        setContactData: (state,action) => {
            state.contactData = action.payload
        },
        setAboutData: (state,action) => {
            state.aboutData = action.payload
        },
        setTeamData: (state,action) => {
            state.teamData = action.payload
        },
        setServiceData: (state,action) => {
            state.serviceData = action.payload
        },
        setGalleryIntroData:(state,action) => {
            state.galleryIntroData = action.payload
        },
        setGalleryData:(state,action) => {
            state.galleryData = action.payload
        },
        setGalleryDataLength:(state,action) => {
            state.galleryDataLength = action.payload
        },
        setVideoData:(state,action) => {
            state.videoData = action.payload
        },
        setVideoDataLength:(state,action) => {
            state.videoDataLength = action.payload
        },
        setEventIntroData:(state,action) => {
            state.eventIntroData = action.payload
        },
        setEventData:(state,action) => {
            state.eventData = action.payload
        },
        setEventDataLength:(state,action) => {
            state.eventDataLength = action.payload
        },
        ReloadData: (state,action) => {
            state.reloadData = action.payload 
        }
    }
})

export const {
    showloading,
    hiddenloading,
    setBannerData,
    setContactData,
    setAboutData,
    setTeamData,
    setServiceData,
    setGalleryIntroData,
    setGalleryData,
    setGalleryDataLength,
    setVideoData,
    setVideoDataLength,
    setEventIntroData,
    setEventData,
    setEventDataLength,
    ReloadData 
} = rootSlice.actions

export default rootSlice.reducer