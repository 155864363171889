import { message } from "antd"
import JoditEditor from "jodit-react"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ReloadData, hiddenloading, showloading } from "../../../../Redux/Server/rootSlice"
import axios from "axios"
import {URL} from '../../../../Url/Url'

const AboutContent = () => {

    const { aboutData } = useSelector((state) => state.root)

    const [description,setDescription] = useState('')
    const [quote,setQuote] = useState('')


    const token = localStorage.getItem('token')
    const dispatch = useDispatch()
    const editor = useRef(null);

   
    useEffect(() => {
        setDescription(aboutData.description)
        setQuote(aboutData.quote)
    },[aboutData])
    
    const onSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const {data} = await axios.patch(`${URL}/api/Gebriel/about/content`,{
                description,quote
            },config)
            dispatch(hiddenloading())
            if(data.success === true){
                message.success('About content Updated Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }
    }

    return(
        <div className="flex flex-col gap-5">
            <form onSubmit={onSubmit}>
                <h1 className="text-2xl mt-5 font-semibold text-primary text-center uppercase">About Description</h1>
                    <div className="flex flex-col mt-5 gap-5">
                        <JoditEditor
                        ref={editor}
                        value={description}
                        onChange={newContent => setDescription(newContent)}     
                        />
                    </div>
                <h1 className="text-2xl mt-5 font-semibold text-primary text-center uppercase">Quote</h1>
                    <div className="flex flex-col mt-5 gap-5">
                        <JoditEditor
                        ref={editor}
                        value={quote}
                        onChange={newContent => setQuote(newContent)}     
                        />
                    </div>
                <div className="flex mt-5 justify-end w-full">
                        <button type="submit" className="bg-Primary text-white w-[100px] py-2 px-5 rounded">Update</button>
                </div>
        </form>
     </div>
    )
}

export default AboutContent;