/* eslint-disable no-unused-vars */
import { Form, message } from "antd"
import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { hiddenloading, ReloadData, showloading } from "../../../../Redux/Server/rootSlice"
import {URL} from '../../../../Url/Url'


const AboutBanner = () => {

    const { aboutData } = useSelector((state) => state.root)
    const [preview,setPreview] = useState('')
    const [intro_image,setIntroImage] = useState('')
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')

    useEffect(() => { 
        setIntroImage(aboutData.intro_image)
        setPreview(aboutData.intro_image.url)
    },[aboutData])


    const handleFileInputChange = (e) => {
        const file = e.target.files[0]
        
        transformFile(file)
    }

    

    const transformFile = (file) => {
        const reader = new FileReader();
        if(file){
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setIntroImage(reader.result);
                setPreview(reader.result)
            };
        }
    }
   
    const onSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const {data} = await axios.patch(`${URL}/api/Gebriel/about`,{
                intro_image
            },config)
            dispatch(hiddenloading())
            if(data.success === true){
                message.success('About content Updated Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }
    }

    return(
        <div className="flex flex-col gap-8">
            <div>
            <img className="w-full h-[400px] border-2 rounded object-cover " src={preview} alt=""/>
            </div>
            <div className="flex w-full items-center gap-5" >
                <div className="w-full ">
                    <form onSubmit={onSubmit}>
                        
                        <input className="cinput w-full" type="file" onChange={handleFileInputChange}  />
                        

                        <div className="flex justify-end mt-5 gap-5 w-full">
                            <button type="submit" className="bg-Primary text-white w-[150px] px-5 py-1 rounded">Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AboutBanner;