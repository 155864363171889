import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../Components/SidebarComponent/sidebar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { reset } from "../../Redux/auth/authSlice";
import ScreenError from "./ScreenError";
import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import AboutBanner from "../Components/AdminAboutComponents/AboutBanner/AboutBanner";
import AboutContent from "../Components/AdminAboutComponents/AboutContent/AboutContent";
import TeamManagement from "../Components/AdminAboutComponents/Team Management/TeamManagement";

const AdminAboutPage = () => {

    const { aboutData, teamData } = useSelector((state) => state.root)

    useEffect(() => {
        document.title = "Admin About Page Management - Debre Amin Kidus Gebriel Church"
    })

    const [show,setShow] = useState(true)

    const ScreenSize = () => {
        if(window.innerWidth <= 1080){
            setShow(false)
        }
        else {
            setShow(true)
        }
    }

    useEffect(() => {
        ScreenSize()
        window.addEventListener('resize',() => {ScreenSize()})
    },[])

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user } = useSelector((state) => state.auth)
    
    useEffect(() => {
    
        if (!user) {
          navigate('/administrator')
        }
    
        return () => {
          dispatch(reset())
        }
      }, [user, navigate, dispatch])

    return(
        <div>
            {!show ? <ScreenError/> : <div>
                {aboutData && teamData && (
                    <div  className="flex">
                        <Sidebar/>
                        <div className="flex flex-col w-full px-20 py-10 ml-[260px]">
                            <Tabs  defaultActiveKey="1">
                                <TabPane tab='About Banner' key="1">
                                   <AboutBanner/>
                                </TabPane>
                                <TabPane tab='About Content' key="2">
                                    <AboutContent/>
                                </TabPane>
                                <TabPane tab='Our Team' key="3">
                                    <TeamManagement/>
                                </TabPane>
                            </Tabs>
                        </div>    
                    </div>
                )}
            </div>}
        </div>
    )
}


export default AdminAboutPage;