/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import Slider from "react-slick"
import Card from "./Card"
import { useEffect, useState } from "react"

const GalleryFrame = ({GalleryData}) => {

    const [selectedImg,setSelectedImg] = useState(GalleryData.gallery_images[0])
    const [progress,setProgress] = useState(0)
    const [slidetoshow,setslidetoshow] = useState(4)


    const setslide = () => {
        if(window.innerWidth <= 1280 && window.innerWidth > 1000){
            setslidetoshow(3)
        }
        else if(window.innerWidth <=1000 && window.innerWidth > 780){
            setslidetoshow(2)
        }
        else if(window.innerWidth <= 650){
            setslidetoshow(1)
        }
    }   
        useEffect(() => {
            setslide()
            setProgress(100/(GalleryData.length - slidetoshow + 1))
            window.addEventListener('resize',() => {setslide()})
        },[])

        const settings = {
          dots: true,
          nextArrow: false,
          prevArrow: false,
          infinite: false,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            // {
            //   breakpoint: 480,
            //   settings: {
            //     slidesToShow: 2,
            //     slidesToScroll: 2
            //   }
            // }
          ]
        };

    return(
        <div className="gallery relative">
            <h1 className="text-2xl uppercase font-bold text-center mt-10 mb-5">{GalleryData.gallery_name}</h1>
            <div className="flex flex-col w-full justify-center items-center mb-5">
              <img className="w-[550px] vsmm:w-[300px] border-2 rounded object-contain" src={selectedImg.url} alt="img"/>
            </div>
            <Slider {...settings}>
                {GalleryData.gallery_images.map((data,index) => (
                    <div onClick={() => setSelectedImg(data)} key={index} >
                        <Card img={data.url}/>
                        
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default GalleryFrame;