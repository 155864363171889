import { Modal, message } from "antd"
import { useEffect, useState } from "react"
import {URL} from '../../../Url/Url'
import { ReloadData, hiddenloading, showloading } from "../../../Redux/Server/rootSlice"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import Pagination from "./Pagination";
import { useLocation } from "react-router-dom";
import ReactPlayer from 'react-player'

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

const VideoGallery = () => {

    const { videoData } = useSelector((state) => state.root)

    const [showAddEditModal,setShowAddEditModal] = useState(false)
    const [selectedItemforEdit,setSelectedItemforEdit] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteID,setDeleteId] = useState(null)
    const [video_name,setVideoName] = useState('')
    const [video_url,setVideoUrl] = useState('')
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')
    const query = useQuery()
    const page = query.get('page') || 1; 

    useEffect(() => {
        if(selectedItemforEdit){
            setVideoName(selectedItemforEdit.video_name)
            setVideoUrl(selectedItemforEdit.video_url)

        }else{           
            setVideoName('')
            setVideoUrl('')
        }
    },[selectedItemforEdit])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            if(selectedItemforEdit){
                const {data} = await axios.patch(`${URL}/api/Gebriel/video/`+ selectedItemforEdit._id,{video_name,video_url},config)
                dispatch(hiddenloading())
                if(data.success === true){
                    setShowAddEditModal(false)
                    setVideoName('')
                    setVideoUrl('')
                    message.success("Video Updated Successfuly")
                    dispatch(hiddenloading())
                    dispatch(ReloadData(true))
                }
            }else{
                const {data} = await axios.post(`${URL}/api/Gebriel/video`,{video_name,video_url},config)
                dispatch(hiddenloading())
                if(data.success === true){
                    setShowAddEditModal(false)
                    setVideoName('')
                    setVideoUrl('')
                    message.success("Video Added Successfuly")
                    dispatch(hiddenloading())
                    dispatch(ReloadData(true))
                }
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }

    }

    const handleDelete = async (id) => {
        try{
            const config = {
                headers:{
                    Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const data = await axios.delete(`${URL}/api/Gebriel/Video/` + id, config)
            dispatch(hiddenloading())
            if(data.data.success === true){
                message.success('Video Deleted Successfully')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            message.error(err.message)
        }
    }

    return(
        <div>
            <div className="flex flex-col">
            <div className="flex justify-end">
                <button className="bg-Primary  text-white w-[200px] py-2 px-5 rounded" onClick={() => {
                    setSelectedItemforEdit(null)
                    setShowAddEditModal(true)
                }}>Add New Video
                </button>
            </div>
            <hr className="mt-5 mb-5"/>

            <div >
                {videoData.length === 0 ? <img className="w-[400px] object-contain" src="https://res.cloudinary.com/dbewtrc1q/image/upload/v1687779786/Bimer%20Extra%20Items/dprgbk8ejv3tw0pkocab.jpg" alt="no_item_found"/> : <div className="flex flex-wrap justify-center mt-10 gap-8 w-full">
                    {videoData.map((item) => (
                        <div className="flex flex-col  w-[275px] rounded-md">
                            <ReactPlayer className="mb-10 sm:mb-5"  url={item.video_url} controls={true} width={"275px"} height={"200px"}/>
                            <h1 className="text-xl font-semibold text-center mb-3">{item.video_name}</h1>
                            <div className="flex gap-3 mt-3 w-full">
                                <button className="p-2 text-white bg-Secondary w-1/2" onClick={() => {
                                    setSelectedItemforEdit(item)
                                    setShowAddEditModal(true)
                                }}>Edit</button>
                                 <button className="p-2 text-white bg-red-500 w-1/2" onClick={() => {
                                    setDeleteId(item._id)
                                    setShowDeleteModal(true)
                                }}>Delete</button>
                            </div>
                        </div>
                    ))}
                </div>}
            </div>
            <div className={videoData.length === 0 ? " hidden" : "flex justify-center mt-5 "}>
                    <Pagination page={page}/>
            </div>
        </div>
        <Modal visible={showAddEditModal} width={'800px'} footer={null} onCancel={() => {setShowAddEditModal(false); setSelectedItemforEdit(null)}}>
            <h1 className="text-center text-xl uppercase font-semibold mt-5 mb-5">{selectedItemforEdit ? 'Update Video' : 'Add New Video' }</h1>   
            <form onSubmit={handleSubmit}>
                <div className="flex gap-5 w-full items-center">
                    <div className="flex flex-col w-full">
                        <div className="flex flex-col gap-1 w-full">
                            <label className="font-bold uppercase">Video Name:</label>
                            <input className="cinput w-full" type="text" onChange={(e) => setVideoName(e.target.value)} value={video_name}/>
                        </div>
                    
                        <div className="flex flex-col gap-1 w-full mt-2">
                            <label className="font-bold uppercase">Video Url:</label>
                            <input className="cinput w-full" type="text" onChange={(e) => setVideoUrl(e.target.value)} value={video_url}/>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end mt-3 gap-5 w-full">
                    <button type="submit" className="bg-Primary text-white w-[150px] px-5 py-1 rounded">{selectedItemforEdit ? 'Update Video' : 'Add Video'}</button>
                </div>
            </form>
         
       </Modal>
       <Modal visible={showDeleteModal} footer={null} closable={false} centered={true} onCancel={() => {setShowDeleteModal(false); setDeleteId(null)}}>
                <h1 className="text-center text-2xl">Are you sure want to delete?</h1>
                <div className="flex justify-center items-center gap-5 mt-5">
                    <button className="bg-Secondary w-[80px] p-1 rounded text-white" onClick={() => {handleDelete(deleteID); setShowDeleteModal(false)}}>Ok</button>
                    <button className="bg-red-500 w-[80px] p-1 rounded text-white" onClick={() => {
                        setShowDeleteModal(false) 
                        setDeleteId(null)
                    }}>Cancel</button>
                </div>
        </Modal>
    </div>
    )
}

export default VideoGallery;