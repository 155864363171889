import { BrowserRouter as Router, Routes , Route } from 'react-router-dom';
import HomePage from './Clients/Screens/HomePage.js';
import AboutPage from './Clients/Screens/AboutPage';
import GalleryPage from './Clients/Screens/GalleryPage';
import EventPage from './Clients/Screens/EventPage';
import ContactPage from './Clients/Screens/ContactPage';
import NotFoundPage from './Clients/Screens/NotFoundPage';
import AdminLoginPage from './Admin/Screens/AdminLoginPage.js';
import AdminHomePage from './Admin/Screens/AdminHomePage.js';
import AdminAboutPage from './Admin/Screens/AdminAboutPage.js';
import AdminGalleryPage from './Admin/Screens/AdminGalleryPage.js';
import AdminEventPage from './Admin/Screens/AdminEventPage.js';
import AdminContactPage from './Admin/Screens/AdminContactPage.js';
import AdminSettingPage from './Admin/Screens/AdminSetting.js';
import { useDispatch, useSelector } from 'react-redux';
import {URL} from './Url/Url'
import axios from 'axios';
import { useEffect } from 'react';
import { 
  ReloadData,
  hiddenloading,
  showloading, 
  setContactData, 
  setBannerData, 
  setAboutData, 
  setTeamData, 
  setServiceData,
  setGalleryIntroData,
  setGalleryData,
  setGalleryDataLength, 
  setVideoData,
  setVideoDataLength,
  setEventIntroData,
  setEventData,
  setEventDataLength,
   } from './Redux/Server/rootSlice.js';
import ServiceDetails from './Clients/Screens/ServiceDetails.js';
import GalleryDetails from './Clients/Screens/GalleryDetails.js';
import EventDetails from './Clients/Screens/EventDetails.js';
import LoadingPage from './Clients/Screens/LoadingPage.js';

function App() {

  var hours = 12
  var now  = new Date().getTime()
  var setupTime = localStorage.getItem('setupTime')

  if(setupTime === null){
    localStorage.setItem('setupTime',now)
  }
  else{
    if(now - setupTime > hours*60*60*1000){
      localStorage.clear()
      localStorage.setItem('setupTime',now)
    }
  }

  const {
    isLoading,
    bannerData,
    contactData,
    aboutData,
    teamData,
    serviceData,
    galleryIntroData,
    galleryData,
    galleryDataLength,
    videoData,
    videoDataLength,
    eventIntroData,
    eventData,
    eventDataLength,
    reloadData} = useSelector((state) => state.root)
  
  const dispatch = useDispatch()

  // get Banner Data

  const getBannerData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Gebriel/banner`)
      dispatch(setBannerData(responce.data.banner))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
    }catch(err){
      dispatch(hiddenloading())
    }
  }
  
  useEffect(() => {
    if(!bannerData){
      getBannerData()
    }
  },[bannerData])

  // get Contact Data

  const getContactData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Gebriel/contact`)
      dispatch(setContactData(responce.data.contact))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!contactData){
      getContactData()
    }
  },[contactData])

  // get all About Data

  const getAboutData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Gebriel/about`)
      dispatch(setAboutData(responce.data.about))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!aboutData){
      getAboutData()
    }
  },[aboutData])

  const getTeamData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Gebriel/team`)
      dispatch(setTeamData(responce.data.team))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!teamData){
      getTeamData()
    }
  },[teamData])

  // get all services data

  const getServiceData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Gebriel/service`)
      dispatch(setServiceData(responce.data.services))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!serviceData){
      getServiceData()
    }
  },[serviceData])

  // get all galleryIntro data

  const getGalleryIntroData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Gebriel/gallery/intro`)
      dispatch(setGalleryIntroData(responce.data.galleryIntro))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!galleryIntroData){
      getGalleryIntroData()
    }
  },[galleryIntroData])

  // get all gallery data

  const getGalleryData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Gebriel/gallery`)
      dispatch(setGalleryData(responce.data.gallery))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!galleryData){
      getGalleryData()
    }
  },[galleryData])

  // get Gallery Data

  const getGalleryLength = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Gebriel/gallery/length`)
      dispatch(setGalleryDataLength(responce.data.gallery))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!galleryDataLength){
      getGalleryLength()
    }
  },[galleryDataLength])

  // get Video Data

  const getVideoData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Gebriel/video`)
      dispatch(setVideoData(responce.data.video))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!videoData){
      getVideoData()
    }
  },[videoData])

  // get video length

  const getVideoDataLength = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Gebriel/video/length`)
      dispatch(setVideoDataLength(responce.data.video))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!videoDataLength){
      getVideoDataLength()
    }
  },[videoDataLength])

  // get all Event Data

  const getEventIntroData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Gebriel/event/intro`)
      dispatch(setEventIntroData(responce.data.eventIntro))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!eventIntroData){
      getEventIntroData()
    }
  },[eventIntroData])

  // get all event Data 

  const getEventData = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Gebriel/event`)
      dispatch(setEventData(responce.data.event))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!eventData){
      getEventData()
    }
  },[eventData])

  // get event length

  const getEventDataLength = async () => {
    try{
      dispatch(showloading())
      const responce = await axios.get(`${URL}/api/Gebriel/event/length`)
      dispatch(setEventDataLength(responce.data.event))
      dispatch(ReloadData(false))
      dispatch(hiddenloading())
    }catch(err){
      dispatch(hiddenloading())
    }
  }

  useEffect(() => {
    if(!eventDataLength){
      getEventDataLength()
    }
  },[eventDataLength])

  // Reload Data

  useEffect(() => {
    if(reloadData){
      getBannerData()
      getContactData()
      getAboutData()
      getTeamData()
      getGalleryIntroData()
      getGalleryData()
      getGalleryLength()
      getVideoData()
      getVideoDataLength()
      getEventIntroData()
      getEventData()
      getEventDataLength()
    }
  },[reloadData])
  

  return (
    <Router>
      {isLoading ? <LoadingPage/> : null}
      <div className="App">
        <Routes>
          <Route exact path='/' element={<HomePage/>}/>
          <Route path='about' element={<AboutPage/>}/>
          <Route path='gallery' element={<GalleryPage/>}/>
          <Route path='gallery/image/:id' element={<GalleryDetails/>}/>
          <Route path='events' element={<EventPage/>}/>
          <Route path='events/:id' element={<EventDetails/>}/>
          <Route path='service/:id' element={<ServiceDetails/>}/>
          <Route path='contact' element={<ContactPage/>}/>
          <Route path='administrator' element={<AdminLoginPage/>}/>
          <Route path='admindashboard/home' element={<AdminHomePage/>}/>
          <Route path='admindashboard/about' element={<AdminAboutPage/>}/>
          <Route path='admindashboard/gallery' element={<AdminGalleryPage/>}/>
          <Route path='admindashboard/event' element={<AdminEventPage/>}/>
          <Route path='admindashboard/contact' element={<AdminContactPage/>}/>
          <Route path='admindashboard/setting' element={<AdminSettingPage/>}/>
          <Route path='*' element={<NotFoundPage/>}/>
        </Routes>
      </div>
    </Router>
    
  );
}

export default App;
