import { useSelector } from "react-redux";

const AboutSection = ({show}) => {
    const { aboutData } = useSelector((state) => state.root)
    return(
        <div className="w-full flex flex-col px-32 llg:px-20 sm2:px-10  py-10 items-center justify-center">
            
                <div>
                    <div className="flex w-full items-center">
                       
                    
                            <div dangerouslySetInnerHTML={{__html: aboutData.description}}/>
                        
                       
                    </div>
                    <div className="flex gap-10 w-10/12 vsm:w-11/12 mt-10 ">
                       
                            
                
                            <div>
                                <div className=" border-l-2 px-5 border-Primary font-semibold text-xl vsm:text-lg"dangerouslySetInnerHTML={{__html: aboutData.quote}}/>
                            </div>
                    </div>
              </div>
            
        </div>
    )
}

export default AboutSection;