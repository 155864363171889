import { Tabs } from "antd";
import GalleryIntro from "../Components/Gallery Components/Gallery Intro/GalleryIntro";
import Navbar from "../Components/Navbar Components/Main-Navbar/Navbar";
import TabPane from "antd/es/tabs/TabPane";
import Footer from "../Components/Footer Components/Footer";
import GalleryImages from "../Components/Gallery Components/Gallery Images/GalleryImages";
import VideoGallery from "../Components/Video Components/VideoLists/VideoGallery";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const GalleryPage = () => {

    useEffect(() => {
        document.title = "Gallery - Debre Amin Kidus Gebriel Church"
        window.scrollTo(0, 0);
    })

    const {galleryData,galleryDataLength,videoData,videoDataLength} = useSelector((state) => state.root)

    return(
        <div>
            {galleryData && galleryDataLength && videoData && videoDataLength && (
                <div className="w-full">
                    <Navbar/>
                    <GalleryIntro/>
                    <div className="flex flex-col p-5 w-full">
                        <Tabs defaultActiveKey="1" centered>
                            <TabPane tab={<h1 className="text-xl uppercase">Images</h1>} key="1">
                                <GalleryImages/>
                            </TabPane>
                            <TabPane tab={<h1 className="text-xl uppercase">Videos</h1>} key="2">
                                <VideoGallery/>
                            </TabPane>
                        </Tabs>
                    </div>
                    <Footer/>
                </div>
            )}
        </div>
    )
}

export default GalleryPage;