import { Modal, message } from "antd";
import { useEffect, useRef, useState } from "react";
import JoditEditor from "jodit-react"
import { useDispatch, useSelector } from "react-redux";
import { ReloadData, hiddenloading, showloading } from "../../../../Redux/Server/rootSlice";
import axios from "axios";
import {URL} from '../../../../Url/Url'
import Pagination from "./Pagination";
import { useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

const EventDescription = () => {

    const [showAddEditModal,setShowAddEditModal] = useState(false)
    const [selectedItemforEdit,setSelectedItemforEdit] = useState(null)
    const [showDeleteModal,setShowDeleteModal] = useState(false)
    const [deleteID,setDeleteId] = useState(null)
    const [event_name,setEventName] = useState('')
    const [event_desc,setEventDesc] = useState('')
    const [event_date,setEventData] = useState('')
    const [event_image,setEventImage] = useState('')
    const [preview,setPreview] = useState('')
    const dispatch = useDispatch()
    const editor = useRef(null);
    const query = useQuery()
    const page = query.get('page') || 1; 

    const token = localStorage.getItem('token')

    const {eventData} = useSelector((state) => state.root)


    const handleFileInputChange = (e) => {
        const file = e.target.files[0]
        previewFile(file)
        
    }

    const previewFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () =>{
            setEventImage(reader.result);
            setPreview(reader.result)
        }
    }

    useEffect(() => {
        if(selectedItemforEdit){
            setEventData(selectedItemforEdit.event_date)
            setEventDesc(selectedItemforEdit.event_desc)
            setEventImage(selectedItemforEdit.event_image)
            setEventName(selectedItemforEdit.event_name)
            setPreview(selectedItemforEdit.event_image.url)
        }
        else{
            setEventData('')
            setEventDesc('')
            setEventImage('')
            setEventName('')
            setPreview('')
        }
    },[selectedItemforEdit])
     
    const submitForm = async (e) => {

        e.preventDefault();
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }

            if(!event_name || !event_desc || !event_image){
                message.error('Please Enter Event Name, Description, Date & Image')
            }else{
            
            if(selectedItemforEdit){
                dispatch(showloading())
                const {data} = await axios.patch(`${URL}/api/Gebriel/event/`+ selectedItemforEdit._id,{event_date,event_desc,event_image,event_name},config)
                if(data.success === true){
                    setShowAddEditModal(false)
                    setEventData('')
                    setEventDesc('')
                    setEventImage('')
                    setEventName('')
                    dispatch(ReloadData(true))
                    dispatch(hiddenloading())
                    message.success('Event updated successfully')
                }
            }
            else{
                dispatch(showloading())
                const {data} = await axios.post(`${URL}/api/Gebriel/event`,{event_date,event_desc,event_image,event_name},config)
                
                if(data.success === true){
                    
                    setShowAddEditModal(false)
                    setEventData('')
                    setEventDesc('')
                    setEventImage('')
                    setEventName('')
                    dispatch(ReloadData(true))
                    dispatch(hiddenloading())
                    message.success('Event created successfully')
                }
            }
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }

    }

    const handleDelete = async (id) => {
        try{
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const data = await axios.delete(`${URL}/api/Gebriel/event/`+ id,config)
            dispatch(hiddenloading())
            if(data.data.success === true){
                message.success('Event Deleted Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            message.error(err.message)
        }
    }

    return(
        <div>
            <div className="flex flex-col">
                <div className=" flex  justify-end"> 
                    <button className="bg-Primary text-white w-[200px] py-2 px-5 rounded" onClick={() => {
                    setSelectedItemforEdit(null);
                    setShowAddEditModal(true)
                }}>Add Event</button>
                </div>
                <hr className="mt-5 mb-5"/>
                <div >
                {eventData.length === 0 ? <img className="w-[400px] object-contain" src="https://res.cloudinary.com/dbewtrc1q/image/upload/v1687779786/Bimer%20Extra%20Items/dprgbk8ejv3tw0pkocab.jpg" alt="no_item_found"/> : <div className="flex flex-wrap justify-center mt-10 gap-8 w-full">
                    {eventData.map((item) => (
                        <div className="flex flex-col  w-[275px] rounded-md">
                            <img className="w-[275px] h-[200px] " src={item.event_image.url}  alt="EventImage"/>   
                            <h1 className="text-xl font-semibold text-center mt-3">{item.event_name}</h1>
                            <div className="flex gap-3 mt-3 w-full">
                                <button className="p-2 text-white bg-Secondary w-1/2" onClick={() => {
                                    setSelectedItemforEdit(item)
                                    setShowAddEditModal(true)
                                }}>Edit</button>
                                 <button className="p-2 text-white bg-red-500 w-1/2" onClick={() => {
                                    setDeleteId(item._id)
                                    setShowDeleteModal(true)
                                }}>Delete</button>
                            </div>
                        </div>
                    ))}
                </div>}
            </div>
            <div className={eventData.length === 0 ? " hidden" : "flex justify-center mt-5 "}>
                    <Pagination page={page}/>
            </div>
            </div>
            <Modal visible={showDeleteModal} footer={null} closable={false} centered={true} onCancel={() => {setShowDeleteModal(false); setDeleteId(null)}}>
                    <h1 className="text-center text-2xl">Are you sure want to delete?</h1>
                    <div className="flex justify-center items-center gap-5 mt-5">
                        <button className="bg-Secondary w-[80px] p-1 rounded text-white" onClick={() => {handleDelete(deleteID); setShowDeleteModal(false)}}>Ok</button>
                        <button className="bg-red-500 w-[80px] p-1 rounded text-white" onClick={() => {
                            setShowDeleteModal(false) 
                            setDeleteId(null)
                        }}>Cancel</button>
                    </div>
            </Modal>
            <Modal visible={showAddEditModal}  footer={null} onCancel={() => {setShowAddEditModal(false); setSelectedItemforEdit(null)}}>
                <div>
                <h1 className="text-center text-xl uppercase font-semibold mt-5 mb-5">{selectedItemforEdit ? 'Edit Event' : 'Add Event'}</h1>
                <hr/>
                <form onSubmit={submitForm}>
                        <div className="flex flex-col gap-2 mt-2">
                            <label className="font-semibold uppercase ">Event Name:</label>
                            <input className="cinput w-full" placeholder="Event Name" onChange={(e) => setEventName(e.target.value)} value={event_name} />
                        </div>
                        <div className="flex flex-col gap-2 mt-2">
                            <label className="font-semibold uppercase ">Event Date:</label>
                            <input className="cinput w-full" type="date"  onChange={(e) => setEventData(e.target.value)} value={event_date} />
                        </div>
                        <div className="flex flex-col gap-2 mt-2">
                            <JoditEditor
                            ref={editor}
                            value={event_desc}
                            onChange={newContent => setEventDesc(newContent)}     
                            />
                        </div>
                    <div className="flex w-full items-center mt-5">
                        <div className="w-1/2">
                            <img src={preview} alt="" style={{height:'145px',width:'200px',backgroundImage: `url('https://res.cloudinary.com/dbewtrc1q/image/upload/v1683832570/BimerAssets/yvn64h94i0zi2b4a8tfx.jpg')`,backgroundRepeat: 'no-repeat',backgroundSize:'contain'}}/>
                        </div>
                        <div className="flex flex-col gap-2 w-1/2">
                                <label className="font-semibold uppercase ">Image:</label>
                                <input className="cinput w-full" type="file" onChange={handleFileInputChange} />
                            
                        </div>
                    </div>
                    
                    <div className="flex justify-end gap-5 w-full">
                        <button type="submit" className="bg-Primary text-white w-[150px] px-5 py-1 rounded">{selectedItemforEdit ? 'Update Event' : 'Add Event'}</button>
                    </div>
                </form>
                </div>
            </Modal>
        </div>
    )
}

export default EventDescription;