import {Pagination, PaginationItem} from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import { ReloadData, hiddenloading, setVideoData, showloading } from '../../../Redux/Server/rootSlice';
import { useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { URL } from '../../../Url/Url';

const Paginate = ({page}) => {

    const {videoData,videoDataLength} = useSelector((state) => state.root)
    const classes = useStyles();
    const dispatch = useDispatch()

    const getVideoData = async (page) => {
        try{
          dispatch(showloading())
          const responce = await axios.get(`${URL}/api/Gebriel/video?page=${page}`)
          dispatch(setVideoData(responce.data.video))
          dispatch(ReloadData(false))
          dispatch(hiddenloading())
        }catch(err){
          dispatch(hiddenloading())
        }
    }
    const total = videoDataLength.length
    const limit = 8

    const numberOfPage = Math.ceil(total/limit)

    useEffect(() => {
        if(page || !videoData){
            getVideoData(page)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[page])

    return(
        <Pagination 
            classes={{ul:classes.ul}}
            count={numberOfPage}
            page={Number(page) || 1}
            variant='outlined'
            color='primary'
            renderItem={ (item) => (
                <PaginationItem {...item} component={Link} to={`/admindashboard/gallery?page=${item.page}`}/>
            )}
        />
    )
}

export default Paginate;