import {useSelector } from "react-redux";
import Pagination from "./Pagination";
import { useLocation } from "react-router-dom";
import ReactPlayer from 'react-player'
import { useEffect, useState } from "react";

function useQuery() {
    return new URLSearchParams(useLocation().search)
}


const VideoGallery = () => {

    const {videoData} = useSelector((state) => state.root)
    const query = useQuery()
    const page = query.get('page') || 1;

    const [width,setWidth] = useState('')
    const [height,setHeight]= useState('')

    const setslide = () => {
        // if(window.innerWidth <= 1920 && window.innerWidth > 854){
        //     setWidth('854px')
        //     setHeight('480px')
        // }
        // else if(window.innerWidth <=854 && window.innerWidth > 640){
        //     setWidth('640px')
        //     setHeight('360px')
        // }
        if(window.innerWidth <= 1920 && window.innerWidth > 426){
            setWidth('426px')
            setHeight('240px')
        }
        else if(window.innerWidth <= 426 ){
            setWidth('300px')
            setHeight('169px')
        }
    }   
        useEffect(() => {
            setslide()
            window.addEventListener('resize',() => {setslide()})
        },[])

    return(
        <div>
            <div >
                {videoData.length === 0 ? <div className="flex flex-wrap gap-1 justify-center items-center mb-10"><img className="w-[400px]  object-contain" src="https://res.cloudinary.com/dbewtrc1q/image/upload/v1687779786/Bimer%20Extra%20Items/dprgbk8ejv3tw0pkocab.jpg" alt="no_item_found"/></div> : <div className="flex flex-wrap justify-center mt-10 gap-8 w-full">
                    {videoData.map((item) => (
                        
                            <div className="w-[430px] h-[300px] vsmm:w-[300px] vsmm:h-[200px]  text-xl border-2 rounded-md items-end font-semibold">
                                <ReactPlayer url={item.video_url} controls={true} width={width} height={height}/> 
                                <h1 className="text-xl mb-3 hover:text-Primary font-semibold text-center mt-3">{item.video_name}</h1>
                            </div>
                        
                    ))}
                </div>}
            </div>
            <div className={videoData.length === 0 ? " hidden" : "flex justify-center mt-10 sm:mt-5 "}>
                <Pagination page={page}/>
            </div>
        </div>
    )
}

export default VideoGallery;