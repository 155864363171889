import { useState } from "react";
import {NavLink} from 'react-router-dom';
import './navbar.css'

const GalleryNavbar = () => {
    const items = [
      {name:'Home',link:'/'},
      {name:'About Us',link:'/about'},
      {name:'Gallery',link:'/gallery'},
      {name:'Events',link:'/events'},
      {name:'Contact Us',link:'/contact'}
    ]

    const [open,setOpen] = useState(false)
    const [color,setColor] = useState(false)
    const changecolor = () => {
      if(window.scrollY >= 90){
        setColor(true)
      }
      else{
        setColor(false)
      }
    }

    window.addEventListener('scroll',changecolor)

    return (
      <div className='w-full z-10 fixed top-0 left-0'>
        <div className={`nmd:flex items-center justify-between ${color ? 'bg-Secondary opacity-90 shadow-md': open ? 'bg-Secondary' : 'bg-Secondary'} py-4 nmd:px-10 px-7`}>
        <div className='font-bold text-2xl cursor-pointer flex items-center font-[Poppins] 
        text-gray-800'>
          <img className="w-[80px] ml-5 vvsm:w-[60px] vvsm:ml-0" src="https://res.cloudinary.com/dm8bu2mdo/image/upload/v1699900335/Logo/Gebriel_White_logo-02_nu8kzn.png" alt="Bimer Logo"/>
        </div>
        
        <div onClick={()=>setOpen(!open)} className={`text-3xl text-white absolute right-8 top-6 cursor-pointer nmd:hidden`}>
        <ion-icon name={open ? 'close':'menu'}></ion-icon>
        </div>
  
        <ul className={`nmd:flex nmd:items-center nmd:pb-0 pb-12 absolute nmd:static nmd:z-auto z-[-1] left-0 w-full nmd:w-auto nmd:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-15 bg-Secondary ':'top-[-490px] bg-transparent'}`}>
          {
            items.map((item)=>(
              <li key={item.name} className='nmd:ml-8 text-md nmd:my-0 my-7'>
                <NavLink to={item.link} activeclassName="active" className={`${open ? 'text-white' : color ? 'text-white' : 'text-white'} hover:text-Primary uppercase duration-500`}>{item.name}</NavLink>
              </li>
            ))
          }
        </ul>
        </div>
      </div>
    )
}

export default GalleryNavbar;