const TopFooter = () => {
    return(
        <div className="flex flex-col justify-center gap-10 items-center p-10">
            <img className="w-[150px] ml-5 vvsm:w-[100px] vvsm:ml-0" src="https://res.cloudinary.com/dm8bu2mdo/image/upload/v1699900335/Logo/Gebriel_White_logo-02_nu8kzn.png" alt="Bimer Logo"/>
            <div className="flex flex-wrap justify-center avsm:gap-5  gap-10">
            <a href="/about" className=" hover:text-Primary text-white uppercase text-md">about us</a>
            <a href="/gallery" className=" hover:text-Primary text-white uppercase text-md">Gallery</a>
            <a href="/events" className=" hover:text-Primary text-white uppercase text-md">Events</a>
            <a href="/contact" className=" hover:text-Primary text-white uppercase text-md">Contact us</a>
            </div>
        </div>
    )
}

export default TopFooter;