import {useSelector } from "react-redux";
import Pagination from "./Pagination";
import { Link, useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search)
}


const GalleryImages = () => {

    const {galleryData} = useSelector((state) => state.root)
    const query = useQuery()
    const page = query.get('page') || 1;

    return(
        <div>
            
            <div className="flex flex-wrap gap-1 justify-center items-center mb-10">
                {galleryData.map((p) => (
                    <Link to={`/gallery/image/${p._id}`}>
                        <div style={{backgroundImage: `url(${p.gallery_images[0].url})`, backgroundSize:'cover'}} className={`w-[400px] h-[300px] vsmm:w-[300px] vsmm:h-[200px]  text-xl text-transparent items-end font-semibold hover:text-white `}>
                            <div className=" flex items-end w-full h-full p-5 hover:bg-opacity-80 hover:bg-Primary">{p.gallery_name}</div>
                        </div>
                    </Link>
                ))}   
            </div>
            <div className={galleryData.length === 0 ? " hidden" : "flex justify-center mt-10 sm:mt-5 "}>
                <Pagination page={page}/>
            </div>
        </div>
    )
}

export default GalleryImages;