import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const EventDetail = () => {

    const {id} = useParams()

    const { eventData } = useSelector((state) => state.root)

    useEffect(() => {
        eventData.filter(event =>  event._id === id).map(event => (
            document.title = `${event.event_name} - Debre Amin Kidus Gebriel Church`
        ))
        window.scrollTo(0, 0);
    },[id])

    return(
        <div>
            {eventData.filter(event => event._id === id).map(event => (
                <div>
                    <div className="w-full h-[50vh] vvsm:h-[40vh]" style={{backgroundImage: `url(${event.event_image.url})`,backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
                        <div className="flex flex-col px-20 vvsm:p-3 vs2:gap-3 vvsm:gap-2 z-0 justify-center h-[50vh] bg-black bg-opacity-30 vvsm:h-[40vh] gap-5">
                            <h1 className="text-4xl font-bold vvsm:text-2xl text-white uppercase">{event.event_name}</h1>
                        </div>
                    </div>
                    <div className="w-full flex flex-col px-32 llg:px-20 sm2:px-10  py-10 items-center justify-center">
                        <div className="flex  md:flex-col w-full gap-10 ">         
                            <div className="flex-col  md:w-full w-1/2" dangerouslySetInnerHTML={{__html: event.event_desc}}/>
                            <div className="flex-col w-1/2  md:w-full">
                                <h1 className="text-2xl "><strong>Event Date:</strong> {event.event_date}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default EventDetail;