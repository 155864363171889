import { useSelector } from "react-redux";
import Banner from "../Components/Banner Components/Banner";
import Navbar from "../Components/Navbar Components/Main-Navbar/Navbar";
import Footer from "../Components/Footer Components/Footer";
import Services from "../Components/Services Components/Services";
import HGallery from "../Components/Home Gallery Components/HGalleryComponents/HGallery";
import { useEffect } from "react";

const HomePage = () => {

    useEffect(() => {
        document.title = "Home - Debre Amin Kidus Gebriel Church"
        window.scrollTo(0, 0);
    })

    const { bannerData,serviceData, contactData, galleryDataLength } = useSelector((state) => state.root) 

    return(
        <div>
            {bannerData && serviceData && contactData && galleryDataLength &&(
                <div className="w-full">
                    <Navbar/>
                    <Banner/>
                    <Services/>
                    <HGallery/>
                    <Footer/>
                </div>
            )}
        </div>
    )
}

export default HomePage;