const TCard = ({img,name,title}) => {
    return(
        <div className="w-full border-transparent border-2 hover:shadow-xl rounded mb-5">
            <div className="flex flex-col">
                <img className="h-[350px] w-[600px] rounded rounded-b-none mb-2" src={img} alt="team"/>
                <h1 className="font-semibold text-white uppercase text-center text-md">{name}</h1>
                <h1 className="text-sm text-white text-center mb-5">{title}</h1>
            </div>
        </div>
    )
}

export default TCard;