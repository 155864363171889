import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Navbar from "../Components/Navbar Components/Main-Navbar/Navbar";
import Footer from "../Components/Footer Components/Footer";

import EventDetail from "../Components/Event Components/EventDetail/EventDetail.js";

const EventDetails = () => {

    const {eventData, eventDataLength} = useSelector((state) => state.root)


    return(
        <div>
            {eventData && eventDataLength && (
                <div>
                    <Navbar/>
                    <EventDetail/>
                    <Footer/>
            </div>
            ) }
        </div>
    )
}

export default EventDetails;