import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ReloadData, hiddenloading, showloading } from "../../../../Redux/Server/rootSlice"
import { Modal, message } from "antd"
import axios from "axios"
import { FaTrash } from "react-icons/fa"
import {URL} from '../../../../Url/Url'
import Pagination from "./Pagination";
import { useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

const GalleryManagment = () => {

    const [showAddEditModal,setShowAddEditModal] = useState(false)
    const [selectedItemforEdit,setSelectedItemforEdit] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteID,setDeleteId] = useState(null)
    const [gallery_name,setGalleryName] = useState('')
    const [gallery_images,setGalleryImages] = useState([])
    const [preview, setPreview] = useState([])
    const [newPreview,setNewPreview] = useState([])
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')
    const query = useQuery()
    const page = query.get('page') || 1; 

    const {galleryData} = useSelector((state) => state.root)

    useEffect(() => {
        if(selectedItemforEdit){
            setGalleryImages(selectedItemforEdit.gallery_images)
            setPreview(selectedItemforEdit.gallery_images)
            setGalleryName(selectedItemforEdit.gallery_name)
        }else{           
            setGalleryImages([])
            setPreview([])
            setGalleryName('')
        }
    },[selectedItemforEdit])

    useEffect(() => {
        if(showAddEditModal === false) {
            setPreview([])
            setNewPreview([])
        }
    },[showAddEditModal])
    

    const handleImageChange = (e) => {
        const files = e.target.files;
        const imagesArray = [];
    
        for (let i = 0; i < files.length; i++) {
          const reader = new FileReader();
          // eslint-disable-next-line no-loop-func
          reader.onload = () => {
            if (reader.readyState === 2) {
              imagesArray.push(reader.result);
              setGalleryImages(imagesArray);
              setPreview(imagesArray)
            }
          };
    
          reader.readAsDataURL(files[i]);
        }
      };

      const handleNewImageInput = (e) => {
        const files = e.target.files;
        const imagesArray = [];
    
        for (let i = 0; i < files.length; i++) {
          const reader = new FileReader();
          // eslint-disable-next-line no-loop-func
          reader.onload = () => {
            if (reader.readyState === 2) {
              imagesArray.push(reader.result);
              setGalleryImages(imagesArray);
              setNewPreview(imagesArray)
            }
          };
    
          reader.readAsDataURL(files[i]);
        }
      };


      const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            if(selectedItemforEdit){
                const {data} = await axios.patch(`${URL}/api/Gebriel/gallery/`+ selectedItemforEdit._id,{gallery_name,gallery_images},config)
                dispatch(hiddenloading())
                if(data.success === true){
                    setShowAddEditModal(false)
                    setGalleryImages([])
                    setPreview([])
                    setGalleryName('')
                    message.success("Gallery Updated Successfuly")
                    dispatch(hiddenloading())
                    dispatch(ReloadData(true))
                }
            }else{
                const {data} = await axios.post(`${URL}/api/Gebriel/gallery`,{gallery_name,gallery_images},config)
                dispatch(hiddenloading())
                if(data.success === true){
                    setShowAddEditModal(false)
                    setGalleryImages([])
                    setPreview([])
                    setGalleryName('')
                    message.success("Gallery Added Successfuly")
                    dispatch(hiddenloading())
                    dispatch(ReloadData(true))
                }
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }

    }

    const handleImageDelete = async (id) => {
        try{
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
            dispatch(showloading())
            const data = await axios.delete(`${URL}/api/Gebriel/gallery/${selectedItemforEdit._id}/` + id, config)
            dispatch(hiddenloading())
            if(data.data.success === true){
                setShowAddEditModal(false)
                message.success('Vehicles Image Delete Successfuly')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            message.error(err.message)
        }
    }

    const handleDelete = async (id) => {
        try{
            const config = {
                headers:{
                    Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            const data = await axios.delete(`${URL}/api/Gebriel/gallery/` + id, config)
            dispatch(hiddenloading())
            if(data.data.success === true){
                message.success('Gallery Deleted Successfully')
                dispatch(hiddenloading())
                dispatch(ReloadData(true))
            }
        }catch(err){
            message.error(err.message)
        }
    }

    const handleNewImages = async (e) => {
        e.preventDefault();
        try{
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            dispatch(showloading())
            if(selectedItemforEdit){
                const {data} = await axios.patch(`${URL}/api/Gebriel/gallery/image/` + selectedItemforEdit._id,{
                    gallery_images
                },config)
                dispatch(hiddenloading())
                if(data.success === true){
                    setShowAddEditModal(false)
                    setGalleryImages([])
                    setNewPreview([])
                    message.success('Gallery Image Addes Successfuly')
                    dispatch(hiddenloading())
                    dispatch(ReloadData(true))
                }
            }
        }catch(err){
            dispatch(hiddenloading())
            message.error(err.message)
        }
    }

    return(
        <div>
        <div className="flex flex-col">
            <div className="flex justify-end">
                <button className="bg-Primary  text-white w-[200px] py-2 px-5 rounded" onClick={() => {
                    setSelectedItemforEdit(null)
                    setShowAddEditModal(true)
                }}>Add New Gallery
                </button>
            </div>
            <hr className="mt-5 mb-5"/>

            <div >
                {galleryData.length === 0 ? <img className="w-[400px] object-contain" src="https://res.cloudinary.com/dbewtrc1q/image/upload/v1687779786/Bimer%20Extra%20Items/dprgbk8ejv3tw0pkocab.jpg" alt="no_item_found"/> : <div className="flex flex-wrap justify-center mt-10 gap-8 w-full">
                    {galleryData.map((item) => (
                        <div className="flex flex-col  w-[275px] rounded-md">
                            <img className="w-[275px] h-[200px] " src={item.gallery_images[0].url}  alt="VehicleImage"/>   
                            <h1 className="text-xl font-semibold text-center mt-3">{item.gallery_name}</h1>
                            <div className="flex gap-3 mt-3 w-full">
                                <button className="p-2 text-white bg-Secondary w-1/2" onClick={() => {
                                    setSelectedItemforEdit(item)
                                    setShowAddEditModal(true)
                                }}>Edit</button>
                                 <button className="p-2 text-white bg-red-500 w-1/2" onClick={() => {
                                    setDeleteId(item._id)
                                    setShowDeleteModal(true)
                                }}>Delete</button>
                            </div>
                        </div>
                    ))}
                </div>}
            </div>
            <div className={galleryData.length === 0 ? " hidden" : "flex justify-center mt-5 "}>
                    <Pagination page={page}/>
            </div>
        </div>
        <Modal visible={showAddEditModal} width={'800px'} footer={null} onCancel={() => {setShowAddEditModal(false); setSelectedItemforEdit(null)}}>
            <h1 className="text-center text-xl uppercase font-semibold mt-5 mb-5">{selectedItemforEdit ? 'Update Gallery' : 'Add New Gallery' }</h1>   
            <form onSubmit={handleSubmit}>
                <div className="flex gap-5 w-full items-center">
                    <div className="flex flex-col w-full">
                        <div className="flex flex-col gap-1 w-full">
                            <label className="font-bold uppercase">Gallery Name:</label>
                            <input className="cinput w-full" type="text" onChange={(e) => setGalleryName(e.target.value)} value={gallery_name}/>
                        </div>
                    
                        <div className={selectedItemforEdit ? 'hidden' : "flex flex-col gap-1 w-full mt-2"}>
                            <label className="font-bold uppercase">Model Images:</label>
                            <input className="cinput w-full" type="file" multiple onChange={handleImageChange}/>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end mt-3 gap-5 w-full">
                    <button type="submit" className="bg-Primary text-white w-[150px] px-5 py-1 rounded">{selectedItemforEdit ? 'Update Gallery' : 'Add Gallery'}</button>
                </div>
            </form>
            <div className="flex flex-wrap gap-5 mt-5 justify-center items-center">
                {preview.map((data) => (
                    <div className=" w-[200px] object-contain">
                        <img className="h-[135px] object-cover" src={selectedItemforEdit ? data.url : data} alt="pic"/>
                        <button className={selectedItemforEdit ? 'text-red-500 ml-[180px] -mt-[130px] absolute z-10' : 'hidden'} onClick={() => {
                            handleImageDelete(data._id)
                        }}><FaTrash/></button>
                    </div>
                ))}
        </div>
        <div className={selectedItemforEdit ? "flex flex-wrap gap-5 mt-5 justify-center items-center" : "hidden"}>
                {newPreview.map((data) => (
                    <div className=" w-[200px] object-contain">
                        <img className="h-[135px] object-cover" src={data} alt="pic"/>
                    </div>
                ))}
        </div>
        <div className={selectedItemforEdit ? 'flex flex-col gap-2' : 'hidden'}>
            <form onSubmit={handleNewImages}>
                <label className='font-bold mt-10 mb-3'>Project Images</label>
                <input className='cinput w-full' type="file" multiple onChange={selectedItemforEdit ? handleNewImageInput : handleImageChange}/>
                <div className="flex justify-end mt-3 gap-5 w-full">
                    <button type="submit" className="bg-Primary text-white w-[150px] px-5 py-1 rounded">Add Image</button>
                </div>
            </form>
        </div>    
       </Modal>
       <Modal visible={showDeleteModal} footer={null} closable={false} centered={true} onCancel={() => {setShowDeleteModal(false); setDeleteId(null)}}>
                <h1 className="text-center text-2xl">Are you sure want to delete?</h1>
                <div className="flex justify-center items-center gap-5 mt-5">
                    <button className="bg-Secondary w-[80px] p-1 rounded text-white" onClick={() => {handleDelete(deleteID); setShowDeleteModal(false)}}>Ok</button>
                    <button className="bg-red-500 w-[80px] p-1 rounded text-white" onClick={() => {
                        setShowDeleteModal(false) 
                        setDeleteId(null)
                    }}>Cancel</button>
                </div>
        </Modal>
    </div>
    )
}

export default GalleryManagment;