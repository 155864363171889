import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import Swal from 'sweetalert2'

const ContactMessage = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_fjfwlzm', 'template_aovtj5r', form.current, 'hW5DUcxlzv8VNl63e')
        .then((result) => {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Your Message Submit Successfully.',
                showConfirmButton: false,
                timer: 2000
              })
            form.current.reset()
        }, (error) => {
            console.log(error.text);
        });
  };


    return(
        <div className='flex flex-col items-center p-5 '>
            <div className='flex flex-col gap-1'>
                <h1 className='text-2xl text-left font-semibold uppercase mb-5'>Get in Touch</h1>
            </div>
            <form ref={form} onSubmit={sendEmail}>
                <div className='flex flex-col w-[950px] sm2:w-[600px] vs2:w-[320px] gap-5'>
                    <input className='cinput w-full' name="user_name" placeholder='Full Name' type="text"/>
                    <input className='cinput w-full' name="user_email" type="email" placeholder='Email Address'/>
                    <textarea className='ctextarea w-full' name="message"  placeholder='Message' type="text"/>
                    <button className='cbutton  w-[120px]'>Submit</button>
                </div>
            </form>
        </div>
    )
}

export default ContactMessage;