import { Tabs } from "antd";
import Sidebar from "../Components/SidebarComponent/sidebar";
import TabPane from "antd/es/tabs/TabPane";
import GalleryIntro from "../Components/AdminGalleryComponents/GalleryIntro/GalleryIntro";
import GalleryManagment from "../Components/AdminGalleryComponents/GalleryManagement/GalleryManagement";
import VideoGallery from "../Components/Video Components/VideoGallery";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScreenError from "./ScreenError";
import { useNavigate } from "react-router-dom";
import { reset } from "../../Redux/auth/authSlice";

const AdminGalleryPage = () => {

    useEffect(() => {
        document.title = "Admin Gallery Page Management - Debre Amin Kidus Gebriel Church"
    })

    const {galleryData,galleryDataLength,videoData,videoDataLength} = useSelector((state) => state.root)

    const [show,setShow] = useState(true)

    const ScreenSize = () => {
        if(window.innerWidth <= 1080){
            setShow(false)
        }
        else {
            setShow(true)
        }
    }

    useEffect(() => {
        ScreenSize()
        window.addEventListener('resize',() => {ScreenSize()})
    },[])

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user } = useSelector((state) => state.auth)
    
    useEffect(() => {
    
        if (!user) {
          navigate('/administrator')
        }
    
        return () => {
          dispatch(reset())
        }
      }, [user, navigate, dispatch])


    return(

        <div>
            {!show ? <ScreenError/> : <div>
                {galleryData && galleryDataLength  && videoData && videoDataLength && (
                    <div className="flex">
                        <Sidebar/>
                        <div className="flex flex-col w-full px-20 py-10 ml-[260px]">
                            <Tabs  defaultActiveKey="1">
                                <TabPane tab='Gallery Intro' key="1">
                                    <GalleryIntro/>
                                </TabPane>
                                <TabPane tab='Image Gallerys' key="2">
                                    <GalleryManagment/>
                                </TabPane>
                                <TabPane tab='Video Gallerys' key="3">
                                    <VideoGallery/>
                                </TabPane>
                            </Tabs>
                        </div>   
                    </div>
                )}
            </div>}
        </div>
        
    )
}

export default AdminGalleryPage;