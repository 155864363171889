import { useSelector } from "react-redux";

const AboutIntro = () => {
    const { aboutData } = useSelector((state) => state.root)
    return(
        <div className="mb-5">
                <div className="w-full h-[50vh] vvsm:h-[40vh]" style={{backgroundImage: `url(${aboutData.intro_image.url})`,backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
                    <div className="flex flex-col px-20 vvsm:p-3 vs2:gap-3 vvsm:gap-2 z-0 justify-center h-[50vh] bg-black bg-opacity-30 vvsm:h-[40vh] gap-5">
                        
                        <h1 className="text-4xl font-bold vvsm:text-2xl text-white uppercase">About Us</h1>
                       
                    </div>
                </div>
                
        </div>
    )
}

export default AboutIntro;