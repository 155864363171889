import { useParams } from "react-router-dom";
import Footer from "../Components/Footer Components/Footer";
import GallleryDetailShow from "../Components/Gallery Components/Gallery Details/GalleryDetailShow";
import GalleryNavbar from "../Components/Navbar Components/Gallery-Navbar/GalleryNavbar";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const GalleryDetails = () => {


    const {galleryData,galleryDataLength} = useSelector((state) => state.root)

    

    return(
       <div>
            {galleryData && galleryDataLength && (
                 <div className="w-full">
                    <GalleryNavbar/>
                    <GallleryDetailShow/>
                    <Footer/>
                </div>
            )}
       </div>
    )
}

export default GalleryDetails;