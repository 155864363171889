const LoadingPage = () => {
    return(
        <div className="items-center h-screen w-full fixed flex justify-center gap-5 bg-white inset-0 z-10">
           <div className="bg-Primary w-[30px] h-[30px] rounded-full icon_1"></div>
           <div className="bg-Primary w-[30px] h-[30px] rounded-full icon_2"></div>
           <div className="bg-Primary w-[30px] h-[30px] rounded-full icon_3"></div>
        </div>
    )
}

export default LoadingPage;