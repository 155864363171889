import { useParams } from "react-router-dom";

import GalleryFrame from "./GalleryFrame";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const GallleryDetailShow = () => {

    const {id} = useParams()
    const {galleryData} = useSelector((state) => state.root)

    useEffect(() => {
        galleryData.filter(gallery => gallery._id === id).map(gallery => (
            document.title = `${gallery.gallery_name} - Debre Amin Kidus Gebriel Church`
        ))
        window.scrollTo(0, 0);
    },[id])

    return(
        <div>
            {galleryData.filter(image => image._id === id).map(image => (
                <div className="w-[550px] m-auto py-20 vsm:w-full px-10 vsmm:px-5">
                    
                        <GalleryFrame GalleryData={image}/>
                    
                </div>
            ))}
        </div>
    )
}

export default GallleryDetailShow;