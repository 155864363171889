import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../Components/SidebarComponent/sidebar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { reset } from "../../Redux/auth/authSlice";
import ScreenError from "./ScreenError";
import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import ContactForm from "../Components/AdminContactComponents/ContactInfoForm/ContactForm";
import ContactBanner from "../Components/AdminContactComponents/ContactBanner/ContactBanner";

const AdminContactPage = () => {
    const { contactData } = useSelector((state) => state.root)

    useEffect(() => {
        document.title = "Admin Contact Page Management - Debre Amin Kidus Gebriel Church"
    })

    const [show,setShow] = useState(true)

    const ScreenSize = () => {
        if(window.innerWidth <= 1080){
            setShow(false)
        }
        else {
            setShow(true)
        }
    }

    useEffect(() => {
        ScreenSize()
        window.addEventListener('resize',() => {ScreenSize()})
    },[])

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user } = useSelector((state) => state.auth)
    
    useEffect(() => {
    
        if (!user) {
          navigate('/administrator')
        }
    
        return () => {
          dispatch(reset())
        }
      }, [user, navigate, dispatch])

    return(
        <div>
            {!show ? <ScreenError/> : <div>
                {contactData && (
                    <div  className="flex">
                        <Sidebar/>
                        <div className="flex flex-col w-full px-20 py-10 ml-[260px]">
                            <Tabs  defaultActiveKey="1">
                                <TabPane tab='Contact Banner' key="1">
                                   <ContactBanner/>
                                </TabPane>
                                <TabPane tab='Contact Info' key="2">
                                    <ContactForm/> 
                                </TabPane>
                            </Tabs>
                        </div>    
                    </div>
                )}
            </div>}
        </div>
    )
}


export default AdminContactPage;