const EndFooter = () => {

    const today = new Date();
    const year = today.getFullYear();

    return(
        <div className="p-1  bg-Secondary"> 
                <h1 className="mt-2 text-center sm:text-sm uppercase text-white mb-2">Copyright @{year} <a href="https://www.linkedin.com/in/christian-getenet-b08ab0234/">Debre Amin Kidus Gebriel</a> All Rights Reserved.</h1>
        </div>
    )
}

export default EndFooter